import React, { useState } from "react"
import Img from "gatsby-image"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css" // This only needs to be imported once in your app

export default function Project({ node }) {
  const {
    html,
    frontmatter: { title, image, gallery },
  } = node
  console.log("gallery:", gallery)

  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className="o-grid__cell u-1/1">
        <div className="c-news-tile">
          <div
            onClick={
              gallery
                ? () => {
                    setIsOpen(true)
                  }
                : null
            }
            className={`c-news-tile__image ${
              gallery ? "c-news-tile__image--hover" : ""
            }`}
          >
            <Img fluid={image.childImageSharp.fluid} alt={title} />
          </div>
          <h3 className="c-news-tile__title">{title}</h3>
          <div
            className="c-news-tile__intro"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex].childImageSharp.fixed.src}
          nextSrc={
            gallery[(photoIndex + 1) % gallery.length].childImageSharp.fixed.src
          }
          prevSrc={
            gallery[(photoIndex + gallery.length - 1) % gallery.length]
              .childImageSharp.fixed.src
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </>
  )
}
